<template>
  <div class="wrapper">
    <div class="form-image mx-3" />
    <validation-observer
      ref="form"
      slim
    >
      <div class="form-container">
        <div class="d-flex justify-content-center mb-4">
          <img
            src="@/assets/sidebar-logo.png"
            alt="logo"
            width="200"
          >
        </div>
        <b-form
          class="d-flex flex-column"
          @submit.prevent="resetPassword"
        >
          <div>
            <h5>Create New Password</h5>
            <p class="top-text">
              Your new password must be different from previous used passwords
            </p>
          </div>
          <b-alert
            variant="danger"
            :show="!!error"
          >
            {{ error }}
          </b-alert>
          <div>
            <div class="position-relative">
              <validation-provider
                name="Password"
                rules="required|min:6|max:20"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Password"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    ref="password"
                    :type="type"
                    v-model="password"
                    placeholder="Enter your password"
                    :disabled="isBusy"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
                <fa-icon
                  class="icon-hide pointer"
                  icon="eye"
                  fixed-width
                  @click="switchVisibility"
                />
              </validation-provider>
            </div>
            <div class="position-relative mb-5">
              <validation-provider
                name="Password Confirmation"
                rules="required|password:@Password|min:6|max:20"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Password Confirmation"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    :type="type"
                    v-model="confirmPassword"
                    placeholder="Enter your password confirmation"
                    :disabled="isBusy"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
                <fa-icon
                  class="icon-hide pointer"
                  icon="eye"
                  fixed-width
                  @click="switchVisibility"
                />
              </validation-provider>
            </div>
            <b-form-group class="pt-4">
              <b-button
                class="next shadow p-2 rounded"
                type="submit"
                variant="primary"
                block
                :disabled="isBusy"
              >
                Reset Password
                <b-spinner
                  v-if="isBusy"
                  class="ml-1"
                  label="Spinning"
                  small
                />
              </b-button>
            </b-form-group>
          </div>
        </b-form>
      </div>
    </validation-observer>
    <password-updated />
  </div>
</template>

<script>
import api from '@/api'
import PasswordUpdated from '@/components/PasswordUpdated.vue'

export default {
  components: {
    PasswordUpdated
  },

  data: () => ({
    password: '',
    confirmPassword: '',
    error: null,
    type: 'password',
    isBusy: false
  }),

  methods: {
    switchVisibility() {
      this.type = this.type === 'password' ? 'text' : 'password'
    },
    async resetPassword() {
      try {
        this.isBusy = true

        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }

        await api.auth.resetPassword({
          password: this.password,
          confirmPassword: this.confirmPassword,
          code: this.$route.params.id,
          userName: localStorage.getItem('username')
        })
        this.error = this.$options.data().error

        setTimeout(() => {
          localStorage.clear()
          this.$bvModal.show('modal-password-updated')
        }, 2500)
      } catch (response) {
        this.isBusy = false
        this.error = response.data.messageEng
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-image: url('');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
  min-height: 100%;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    display: flex;
  }
}

.form-container {
  padding-top: 16vh;
  background-color: rgba(255, 255, 255, 1);
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media screen and (min-width: 992px) {
    width: 150vw;
  }
}

.form-image {
  align-items: center;
  display: grid;
  justify-content: center;
  min-height: 98vh;
  background-image: url('../assets/login-logo.png');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;

  @media screen and (min-width: 992px) {
    width: 70%;
  }
}

form {
  height: 100%;
  padding-bottom: 10vh;
  margin: 0 15vw 0 15vw;
}

.username {
  background-color: #fff;
  background: url('') no-repeat scroll 7px 5px;
  padding-left: 25px;
  border: 1px solid darkgray;

  @media screen and (min-width: 992px) {
    background-position: 7px 5px;
  }
}

.password {
  background: url('') no-repeat scroll 7px 5px;
  padding-left: 25px;
  border: 1px solid darkgray;

  @media screen and (min-width: 992px) {
    background-position: 7px 5px;
  }
}

.icon-hide {
  color: var(--primary);
  position: absolute;
  right: 10px;
  top: 75%;
  transform: translateY(-65%);
}

.top-text {
  color: #3F3356;
}

.btn-block {
  margin-top: .5rem;
}
</style>
