<template>
  <b-modal
    id="modal-password-updated"
    hide-header
    hide-footer
    centered
  >
    <div class="text-center">
      <img src="@/assets/password-updated.png" />
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="next"
        variant="warning"
        block
        @click="goToLogin"
      >
        Login
      </b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: {
  },

  data: () => ({
  }),

  created() {
  },

  methods: {
    goToLogin() {
      this.$router.push('/login')
    }
  }
}
</script>
